import { Link } from 'gatsby';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';import React from 'react';

function NotFoundPage() {
    return (
        <Layout>
            <MyNavbar active_page="kontakt" />
            <div className="bg-green-600 h-screen py-20">
                <div class="container mx-auto px-2">
                    <h1 className="text-4xl  md:text-5xl lg:text-6xl xl:text-7xl font-extrabold text-center text-white">404 – Sidan kan inte hittas</h1>
                    <p className="text-lg md:text-2xl m-7 md:w-3/4 md:m-auto md:my-10 text-center text-white" >Sidan du letar efter kan tyvärr inte hittas.</p>
                    <div className="w-100 flex justify-center">
                        <Link to="/kontakt" className="bg-red-700 text-white text-md md:text-xl p-4 rounded hover:bg-red-800">Kontakta Oss</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    );
}
export default NotFoundPage;